import styled from 'styled-components';
import IconCheck from '../../images/icon-check.png';

export const ParaStyle = styled.p`
  margin: 0 0 40px 0;
  padding: 0;
  text-align: center;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 18px;
  line-height: 32px;
  a {
    color: #55aa4c;
    transition: 0.25s ease-out;
    @media (max-width: 1399px) {
      font-size: 16px;
      line-height: 25px;
    }
    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 22px;
    }
    :hover {
      color: #0e4c80;
    }
  }
  a > code {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 17px 35px 13px 35px;
    margin: 0 5px 5px 0;
    font-family: 'Adelle-Bold', sans-serif;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #55aa4c;
    border: 2px solid #55aa4c;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
    border-radius: 100px;
    cursor: pointer;
    text-decoration: none;
    @media (max-width: 767px) {
      padding: 10px 20px 6px 20px;
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
    }
    :hover {
      top: -5px;
      color: #55aa4c;
      border-color: #55aa4c;
      box-shadow: 0 5px 0 0 #55aa4c;
    }
  }
  @media (max-width: 1399px) {
    font-size: 16px;
    line-height: 25px;
  }
  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;
export const ImageStyle = styled.img`
  width: 75px;
  height: auto;
  border: 0;
  vertical-align: top;
  margin: 0 0 10px 0;
  @media (max-width: 1399px) {
    margin: 0 0 10px 0;
  }
`;
export const TitleStyle = styled.h3`
  margin: 0 0 35px 0;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  line-height: 35px;
  color: #55aa4c;
  @media (max-width: 1399px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const LinkStyle = styled.a`
  text-decoration: none;
  color: #55aa4c;
  transition: 0.25s ease-out;
  @media (max-width: 1399px) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
  :hover {
    color: #0e4c80;
  }
`;

export const HeaderH2 = styled.h2`
  position: relative;
  margin: 0 0 35px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 40px;
  line-height: 45px;
  color: #0e4c80;
  @media (max-width: 1399px) {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  @media (max-width: 1199px) {
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

export const Container = styled.div`
  text-align: center;
`;
export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
  text-align: left;
`;
export const ListItem = styled.li`
  padding: 0 0 15px 50px;
  background: url(${IconCheck}) no-repeat 0 1px;
  background-size: 30px auto;
  list-style: none;
  margin: 0;
  p {
    margin: 0;
    text-align: left;
  }
  @media (max-width: 767px) {
    padding-top: 1px;
    padding-left: 40px;
    background-position: 0 0;
    background-size: 25px auto;
  }
`;
export const HeaderH4 = styled.h4`
  padding: 0 0 15px 0;
  margin: 0 0 35px 0;
  font-family: 'HelveticaNeue-Light', sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: normal;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const HeaderH5 = styled.h5`
  margin: 0 0 35px 0;
  font-family: 'Figtree', sans-serif;
  font-size: 22px;
  line-height: 28px;
  font-weight: normal;
  color: #55aa4c;
  @media (max-width: 1399px) {
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 25px;
  }
`;
